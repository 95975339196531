<template>
    <div class="">
      <!-- v-if="getNavbarData.userType == 'shopify'" -->
      <ModalVue
        :ref="id"
        :id="id"
        title="<span class='mr-2'>Export Answer & Statistics.</span>"
        size="md">
        <div class="mx-2">
          <div class="">

            <b-form-radio
            v-for="field in fields" :key="field"
              name="export-category"
              v-model="category"
              :value="field"
              class="radio-button my-2 text-capitalize">
              {{ field }}
            </b-form-radio>
          
          </div>
          <p
            style="
              color: var(--Gray-500, #667085);
              font-family: Poppins;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
            "
          >
            Answers and their stats data will be provided in a CSV
            file.
          </p>
          <div class="d-flex justify-content-center">
            <Button :is-loading="isFileDownload" @click="$emit('export', category)">Export</Button>
          </div>
        </div>
      </ModalVue>
    </div>
  </template>
  <script>
  import ModalVue from "../Layout/Modal.vue";
  // import ModalVue from "../../Layout/Modal.vue";
  import Button from "../Layout/Button.vue";
  export default {
    props:{
      isFileDownload:Boolean,
      fields:{
        default:['all','filtered','selected']
      },
      id:{
        default:'Export',
        type:String
      }
    
    },
    components: {
      ModalVue,
      Button,
    },
  
    data() {
      return {
        category: "all",
      };
    },
    computed: {
      checkProducts(){
  
      
        return this.selected<=0
    
      },
    },
    methods: {
      toast(text) {
        this.$toasted.show(text, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      },
      openExportModal() {
        this.category="all"
        this.$refs[this.id].open();
      },
      closeExportModal() {
        this.$refs[this.id].close();
      },
    },
  };
  </script>
  <style scoped>
  .radio-button
    >>> .custom-control-input:checked
    ~ .custom-control-label::before {
    background: #ffa201;
    border-color: #ffa201;
  }
  .radio-button >>> .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem #ffa201;
  }
  </style>